<template>
  <v-container fluid>
    <v-dialog
      v-model="dialogCompleteEnrollment"
      width="500"
      style="max-width: 100%"
    >
      <enroll
        :selectedBatchRoom="selectedBatchRoom[0]"
        @enrollment-completed="enrollmentCompleted"
      ></enroll>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="3" style="background-color: #273746" class="pa-8">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 70%;
            padding: 10px;
          "
        >
          <h2
            style="
              background-color: #dfc35b;
              font-family: Verdana, Geneva, Tahoma, sans-serif;
              color: white;
              text-align: center;
              margin: 0;
              font-size: 18px;
              padding: 10px;
            "
          >
            {{ details.led_text }}
          </h2>

          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex: 1;
              margin-top: 20px;
              margin-bottom: 20px;
              clear: both;
            "
          >
            <v-img
              :src="instructor.photo"
              height="300"
              contain
              alt="profile image"
            />
          </div>
          <div style="text-align: center; clear: both">
            <h3
              style="
                font-weight: bold;
                color: #f5ffff;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
              "
            >
              {{ instructor.name }}
            </h3>
            <h3
              style="
                color: #f5ffff;
                font-weight: normal;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
              "
            >
              {{ instructor.designation }}
            </h3>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9" style="background-color: #273746" class="pt-6">
        <h1
          style="
            color: aliceblue;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
          "
        >
          {{ details.title }}
        </h1>
        <p
          style="
            color: aliceblue;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
          "
        >
          {{ details.description }}
        </p>
        <v-row
          style="
            background-color: white;
            margin-right: 10px;
            margin-left: 3px;
            margin-top: 10px;
          "
        >
          <v-col
            cols="12"
            md="9"
            style="
              height: auto;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <div style="flex: 1"></div>
            <h2
              class="text-center"
              style="font-family: Verdana, Geneva, Tahoma, sans-serif"
            >
              Choose Your Flexible Time
            </h2>
            <div style="flex: 1"></div>
          </v-col>

          <v-container style="background-color: #a9b4be">
            <v-row style="background-color: #a9b4be" class="mobile-margin-left">
              <v-col cols="12" md="9" style="height: auto">
                <v-data-table
                  v-model="selectedBatchRoom"
                  single-select
                  :headers="headers"
                  :items="batchRooms"
                  item-key="id"
                  hide-sort-icon
                  show-select
                  header-align="center"
                  show-footer="false"
                >
                  <template v-slot:item.from_to_date="{ item }">
                    <tr style="margin-bottom: 3px">
                      <td>
                        <div class="small">
                          {{ moment(item.start_date).format("DD MMM, YYYY") }}
                          -
                          {{ moment(item.end_date).format("DD MMM, YYYY") }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.from_to_time="{ item }">
                    <tr style="margin-bottom: 3px">
                      <td>
                        <div class="small">
                          {{
                            moment(item.start_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )
                          }}
                          -
                          {{
                            moment(item.end_time, "HH:mm:ss").format("hh:mm A")
                          }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>

              <v-col
                cols="12"
                md="3"
                style="background-color: #a9b4be; height: auto"
                class="mobile-margin-left"
              >
                <v-row
                  style="
                    margin-right: 2px;
                    margin-top: 1px;
                    background-color: #fff3eb;
                    height: 100%;
                  "
                >
                  <v-col
                    cols="12"
                    style="background-color: #ffffff; height: 124px"
                  >
                    <div class="other-div" style="margin-top: 20px">
                      <h2
                        style="
                          font-size: 20px;
                          font-weight: bold;
                          text-align: center;
                          font-family: Verdana, Geneva, Tahoma, sans-serif;
                          margin: 0;
                        "
                      >
                        &#2547; {{ details.charge }} BDT
                      </h2>
                      <h5
                        style="
                          font-size: 14px;
                          font-weight: bold;
                          text-align: center;
                          color: green;
                          font-family: Verdana, Geneva, Tahoma, sans-serif;
                          margin: 0;
                        "
                        v-if="details.charge_before"
                      >
                        {{
                          Math.floor(
                            ((details.charge_before - details.charge) /
                              details.charge_before) *
                              100
                          )
                        }}% off &nbsp;
                        <s style="color: red"
                          >&#2547;{{ details.charge_before }} BDT</s
                        >
                      </h5>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    style="background-color: #fff3eb; height: 100px"
                  >
                    <div
                      class="enroll-button-div"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 50%;
                        margin-top: 20px;
                      "
                    >
                      <v-btn
                        color="#FFFFFF"
                        style="
                          display: block;
                          background-color: #ed593d;
                          color: #ffffff;
                          margin: 0 auto;
                          text-align: center;
                          margin-left: auto;
                          margin-right: auto;
                        "
                        @click="showEnrollmentDialog()"
                        >Enroll Now</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <iframe
          ref="iframe"
          width="100%"
          :src="details.wp_url"
          frameborder="0"
          scrolling="no"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; "
          allowfullscreen
          :height="iframeHeight"
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  
<script>
import Enroll from "./Enroll.vue";
export default {
  components: { Enroll },
  data() {
    return {
      dialogCompleteEnrollment: false,
      selectedBatchRoom: [],
      headers: [
        { text: "Days", value: "days" },
        { text: "From-To", value: "from_to_date" },
        { text: "Time", value: "from_to_time" },
      ],
      batchRooms: [],
      instructor: {},
      details: {},
      iframeHeight: "",
    };
  },
  mounted() {
    this.loadData();
    this.$nextTick(() => {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.iframeHeight = 4500;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        this.iframeHeight = 2500;
      }
    });
  },
  methods: {
    loadData() {
      return this.$axios.get("/workshops/" + this.$route.params.slug).then(
        (response) => {
          const { batch_rooms, instructor, ...rest } = response.data;
          this.details = rest;
          this.batchRooms = batch_rooms;
          this.instructor = instructor;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    showEnrollmentDialog() {
      if (this.selectedBatchRoom == "") {
        alert("Please select a batch room");
        return;
      }
      this.dialogCompleteEnrollment = true;
    },
    enrollmentCompleted() {
      this.dialogCompleteEnrollment = false;
      // this.$router.push({ name: "workshop" });
    },
  },
};
</script>
<style >
.v-data-footer {
  display: none;
}

.text-start {
  font-size: 10px;
}

td {
  white-space: nowrap;
}

.v-data-table-header__icon.notranslate {
  display: none;
}
@media (max-width: 600px) {
  .mobile-margin-left {
    /* margin-left: 0px; */
  }
}

@media (max-width: 767px) {
  .enroll-button-div {
    text-align: left;
    margin-left: 0;
  }
  .other-div {
    text-align: right;
    margin-right: 0;
  }
}

#iframe {
  overflow-y: auto;
}
</style>
<template>
  <v-card class="pa-4">
    <v-card-title class="text-h5 primary white--text">
      Complete Payment to Enroll
    </v-card-title>
    <v-form ref="form" v-model="formValid">
      <v-text-field
        v-model="firstName"
        label="First Name"
        :rules="$requiredRules"
      ></v-text-field>
      <v-text-field
        v-model="lastName"
        label="Last Name"
        :rules="$requiredRules"
      ></v-text-field>
      <phone-field v-model="countryPhone"></phone-field>
      <v-text-field
        v-model="email"
        label="Email"
        type="email"
        :rules="$requiredRules"
      ></v-text-field>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn width="100%" color="primary" @click="initiatePaymentWindow"
          >Pay</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import PhoneField from "@/components/global/PhoneField.vue";
export default {
  components: { PhoneField },
  props: ["selectedBatchRoom"],
  emits: ["enrollment-completed"],
  data() {
    return {
      countryPhone: "",
      formValid: false,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    };
  },
  methods: {
    initiatePaymentWindow() {
      this.$refs.form.validate();
      if (!this.formValid) {
        return;
      }
      return this.$axios
        .post("/workshops/temp-user/", {
          first_name: this.firstName,
          last_name: this.lastName,
          phone: this.countryPhone.phone,
          country_code: this.countryPhone.country_code,
          email: this.email,
          batch_room: this.selectedBatchRoom.id,
        })
        .then((res) => {
          //open payment window in center
          let newWindow = window.open(
            res.data,
            "Complete Payment",
            "width=600,height=600,scrollbars=yes,status=yes,resizable=yes,screenx=0,screeny=0"
          );
          //check if payment window is closed
          let timer = setInterval(() => {
            if (newWindow.closed) {
              clearInterval(timer);
              this.$emit("enrollment-completed");
            }
          }, 1000);
        });
    },
  },
};
</script>

<style>
</style>